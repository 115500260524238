import React from "react"

import Footer from "../Footer"

import "./layout.less"

const Layout = ({ children, id }) => {
  return (
    <div className={id}>
      {children}
      <Footer />
    </div>
  )
}

export default Layout
