import React from "react"

import "./style.less"

const Decoration = ({ content }) => (
    <div className="page-header">
        <div className="top-graphic"></div>
        <h1>{content}</h1>
        <div className="bottom-graphic"></div>
    </div>
)

export default Decoration
