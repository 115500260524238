import React from "react"
import { Container, Divider } from "semantic-ui-react"

import Layout from "../components/layout"
import SEO from "../components/Seo"
import Header from "../components/Header"
import Decoration from "../components/Decoration"

const AboutPage = () => (
    <Layout id="pages-dark">
        <SEO title="About" description="All the information about the Slashdiablo Private Diablo II server and the rules that applies when playing here" />
        <Header active="about" />
        <Container text>
            <Decoration content="About" />
            <h2 className="serif">How are we different from Battle.net?</h2>
            <p>
                We try to keep it as close to vanilla as possible, with a few quality of life changes:
        <ul>
                    <li>Every character created is automatically saved and will not expire.</li>
                    <li>Each account can hold up to 16 characters.</li>
                    <li>Mutibox is allowed, up to 4 charecters you can play at once so you can transfer items or use additional characters.</li>
                    <li>We allow play with higher resolution HD Mod for a more modern experience. Two versions are available to use, HD1 with up to 1344x700 resolution or HD2 with up to 1068x600 resolution.</li>
                    <li>Slash BH Maphack is provided with Auto reveal, auto party, loot filter, auto fill game names/passwords and descriptions among other features.</li>
                    <li>nodrop=0 on our server, which means all monsters drop something when they're killed. This is similar to playing Battle.Net with a party of 8 in one game.</li>
                    <li>Uber Diablo or Dclone is triggered with an Herb that's sold to any vendor in hell. To make an Herb, cube a Gul Rune + TP scroll in the cube. Sell the Herb in a hell game for Diablo to Walk the earth, kill him for the Annihilus small charm. Uber Diablo will only spawn in that game an Herb is sold in.</li>
                    <li>All runewords can be made anywhere on the server, ladder or non-ladder. Runeword <a target="_blank" rel="noopener noreferrer" href="https://diablo.gamepedia.com/Plague_(Diablo_II_Rune_Word)">Plague</a> is also enabled</li>
                    <li>Everyone plays on the same Realm and can all join each other at anytime, but We have Multi GS which allows players to host games on a server closer to their location, reducing lag (i.e: tele-stutter), while not separating our player-base to separate realms.</li>
                </ul>
            </p>
            <Divider />
            <h2 className="serif">What are the rules?</h2>
            <h3 className="serif">1. Don't be an asshole</h3>
            <p>
                You can also be banned at mod/admin discretion for:
                Purposefully trying to ruin another player's game experience.
                Attempting to scam another user.
                Harassment or hate speech.
                Trading items for anything other than in-game items or services. (real money, virtual currencies..)
        </p>

            <Divider />
            <h3 className="serif">2. Botting is forbidden</h3>
            <p>
                BOTTING IS EXPLICITLY FORBIDDEN AND RESULTS IN PERMANENT BANS, NO THESE ARE NOT GOING TO GET REMOVED AND YOU'RE NOT ALLOWED TO RETURN UNDER A DIFFERENT NAME OR ANY OTHER WAY.
      </p>
            <Divider />
            <h3 className="serif">3. Limit of 4 accounts per IP</h3>
            <p>
                The limit is per IP, no, you can't use 2 IPs/a VPN/anything else to play a full game alone, this is also bannable.
                The 4 connections are there if you want to mule items, use additional characters to increase the experience gain or use multiple characters, the additional characters must be controlled by a human.
                No, you can't play on one and run any kind of bot on any of the others, everything is manual.
        </p>
            <Divider />
            <h3 className="serif">4. Bannable offenses</h3>
            <p>
                Any kind of automation/hacking other than the provided maphack is not allowed, that includes changes brought to it without permission
                no pickit
                no etal
                no chicken
                no auto-tele
                no packet manipulation
                no duping
                no server crashing/lagging
                The provided maphack is the only thing you are allowed to use on top of the game
        </p>
            <Divider />
            <h3 className="serif">5. Limits</h3>
            <p>
                Don't try to push limits by doing something you were told not to, don't be rude to users/moderators/admins just because you feel entitled to something.
                Remember:
                You are entitled to nothing here, so it's in your best interest to be aware of everything on this page.
                Permanent bans are PERMANENT, you have a single chance to abide by the norms, failing to do so will remove your privilege to play here.
        </p>
        </Container>
    </Layout >
)

export default AboutPage
